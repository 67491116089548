import { VStack, Box, Button, Divider, Icon, useDisclosure } from '@chakra-ui/react';

import logo from '../../assets/btma_logo.png';
import React from 'react';
import { sideNavConfig } from '../../constants/sideNavConfig';
import { useHistory, Link } from 'react-router-dom';

import { logOut } from '../../store/actions/authActions';
import { removeMill } from '../../store/actions/millActions';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../api/auth';
import { DASHBOARD_URL, ROOT_URL } from '../../constants/routerUrl';

import { MdAccountCircle } from 'react-icons/md';
import { removeCurrentUserData } from '../../util/localStorageUtils';

import DialogueBox from '../common/DialogueBox';

const SideNav = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.mill);

  const handleClick = (url) => {
    history.push(url);
  };

  const handleLogout = async () => {
    try {
      await logout();
      removeCurrentUserData();
      dispatch(logOut());
      dispatch(removeMill());
      history.push(ROOT_URL);
    } catch (error) {
      removeCurrentUserData();
      dispatch(logOut());
      dispatch(removeMill());
      history.push(ROOT_URL);
    }
  };

  return (
    <>
      <VStack py={5} minH="calc(100vh - 46px)!important" alignItems="flex-start">
        <Link to={DASHBOARD_URL}>
          <Box w="140px" color="white" mb={5} ml={2.5}>
            <img src={logo} />
          </Box>
        </Link>
        <Box w="100%" px={2}>
          {sideNavConfig.map((item) => (
            <Button
              my={2}
              py={2}
              key={item.key}
              isActive={history.location.pathname === item.url}
              disabled={data?.mill?.membershipStatus !== 'APPROVED' && item.key > 1 && item.key < 6}
              variant="sideNavTab"
              leftIcon={item.icon}
              onClick={() => handleClick(item.url)}>
              {item.title}
            </Button>
          ))}
          <Divider />
          <Button
            mt={2}
            py={2}
            isFullWidth={true}
            leftIcon={<Icon as={MdAccountCircle} />}
            variant="logOut"
            onClick={onOpen}>
            Logout
          </Button>
        </Box>
      </VStack>
      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Logout"
        alertMessage="Are you sure you want to logout?"
        performAction={handleLogout}
      />
    </>
  );
};

export default SideNav;
