import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  VStack,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Badge,
  Image,
  Tooltip,
  Divider,
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
  Select,
  Checkbox,
} from '@chakra-ui/react';
import Pagination from './Pagination';
import Loader from '../../snippets/Loader';
import DateRangePicker from './DateRangePicker';
import { TiExport } from 'react-icons/ti';
import { CgEnter, CgPrinter } from 'react-icons/cg';
import { EditIcon, DeleteIcon, Search2Icon } from '@chakra-ui/icons';
import { MdSearch, MdRefresh, MdDelete, MdModeEdit, MdCancel } from 'react-icons/md';
import {
  AiFillCheckSquare,
  AiOutlineCheckSquare,
  AiOutlineFilePdf,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import { RiUserFollowLine, RiUserUnfollowLine } from 'react-icons/ri';
import Placeholder from '../../../assets/placeholder.jpg';

const DataTable = ({
  caption,
  columns,
  data,
  topPanel,
  actions,
  isPaginated,
  entries,
  currentPage,
  currentPageSize,
  keySearch,
  selectOptions,
  dateRange,
  paginate,
  search,
  pageSizes,
  onActionClick,
  rowSelectScheme,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selected, setSelected] = useState('');
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [searchParams, setSearchParams] = useState();
  const [dateRangeValidation, setDateRangeValidation] = useState(true);
  const [clearDateSelect, setClearDateSelect] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  /* this resets all the search fields and sets table to default initial state */
  const clearSearchFields = () => {
    setSearchKey('');
    setSelected();
    setStartDate('');
    setEndDate('');
    setClearDateSelect(true);
    search?.({ searchKey: '', selected: '', startDate: '', endDate: '' });
  };

  /* this performs search action upon date range validation */
  const handleSearch = (event) => {
    event?.preventDefault();
    if (dateRangeValidation) {
      if (searchKey || selected || startDate || endDate) {
        setSearchParams({ searchKey, selected, startDate, endDate });
      } else {
        setSearchParams();
      }
      search?.({ searchKey, selected, startDate, endDate });
    }
  };

  /* this formats the date to a display format (ex: 10-Mar-2021) */
  const formatDate = (date) => {
    function join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }

    let a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
    return date ? join(new Date(date), a, '-') : '';
  };

  /* this formats the date to a format required for query (ex: 2021-03-21) */
  const formatDateForParams = (date) => {
    function join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }

    let a = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
    return date ? join(new Date(date), a, '-') : '';
  };

  /* this formats the date range and assigns them to state */
  const handleDateRange = (data) => {
    setStartDate(formatDateForParams(data.startDate));
    setEndDate(formatDateForParams(data.endDate));
  };

  /* this sets unique color codes for the badges */
  const getBadgeColor = (colorScheme, data) => {
    if (colorScheme?.length > 0) {
      for (let object of colorScheme) {
        if (object?.group === data) {
          return object?.color || 'yellow';
        } else if (colorScheme?.indexOf(object) === colorScheme?.length - 1) {
          return 'yellow';
        }
      }
    } else {
      return 'yellow';
    }
  };

  /* this renders the tooltip text while hovering over the badges
  here 'item' is the row object and 'accessor' is the array of attributes that is rendered */
  const getBadgeTooltipText = (item, accessor) => {
    let tooltipString = '';

    function convertToWords(str) {
      let result = str.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }

    function checkIfIsoDate(str) {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return str;
      return formatDate(str);
    }

    if (accessor?.length > 0) {
      accessor?.map((attribute, index) => {
        let attrNaming = attribute?.split(' as ');
        item[attrNaming?.[0]]
          ? (tooltipString += `${
              attrNaming?.[1] || convertToWords(attrNaming?.[0])
            }: ${checkIfIsoDate(item[attrNaming?.[0]])}${
              index === accessor.length - 1 ? '.' : ','
            } `)
          : (tooltipString += '');
      });
    } else {
      return tooltipString;
    }
    // return without the trailing comma
    return tooltipString.slice(0, -2);
  };

  /* this sets unique variants for action buttons */
  const getButtonVariant = (actionScheme, data) => {
    function fetchVariant(variant) {
      switch (variant) {
        case 'print':
          return { bgColor: '#2A4365', hoverColor: '#BEE3F8', color: '#2A4365', disabled: false };
        case 'read':
          return { bgColor: '#22543D', hoverColor: '#C6F6D5', color: '#22543D', disabled: false };
        case 'write':
          return { bgColor: '#2A4365', hoverColor: '#BEE3F8', color: '#2A4365', disabled: false };
        case 'kill':
          return { bgColor: '#862E2E', hoverColor: '#FED7D7', color: '#862E2E', disabled: false };
        case 'activate':
          return { bgColor: '#862E2E', hoverColor: '#FED7D7', color: '#862E2E', disabled: false };
        case 'deactivate':
          return { bgColor: '#22543D', hoverColor: '#C6F6D5', color: '#22543D', disabled: false };
        default:
          return { bgColor: 'white', hoverColor: 'white', color: 'white', disabled: true };
      }
    }

    if (actionScheme?.length > 0) {
      for (let object of actionScheme) {
        if (object?.group === data) {
          return fetchVariant(object?.variant);
        } else if (object?.group === 'boolean') {
          return data ? fetchVariant(object?.trueVariant) : fetchVariant(object?.falseVariant);
        } else if (actionScheme?.indexOf(object) === actionScheme?.length - 1) {
          return fetchVariant();
        }
      }
    } else {
      return fetchVariant();
    }
  };

  /* this sets the tooltip texts for action buttons */
  const getButtonText = (actionScheme, data) => {
    if (actionScheme?.length > 0) {
      for (let object of actionScheme) {
        if (object?.group === data) {
          return object?.action || '';
        } else if (object?.group === 'boolean') {
          return data ? object?.trueAction : object?.falseAction;
        } else if (actionScheme?.indexOf(object) === actionScheme?.length - 1) {
          return '';
        }
      }
    } else {
      return '';
    }
  };

  /* this sets the action for action buttons */
  const getButtonAction = (actionScheme, data, rowObject) => {
    if (actions && actionScheme?.length > 0) {
      for (let object of actionScheme) {
        if (object?.group === data) {
          return object?.action
            ? actions[object.action]
              ? actions[object.action]({ rowObject })
              : () => console.log('action clicked')
            : () => console.log('action clicked');
        } else if (object?.group === 'boolean') {
          return data
            ? object?.trueAction
              ? actions[object.trueAction]
                ? actions[object.trueAction]({ rowObject })
                : () => console.log('action clicked')
              : () => console.log('action clicked')
            : object?.falseAction
            ? actions[object.falseAction]
              ? actions[object.falseAction]({ rowObject })
              : () => console.log('action clicked')
            : () => console.log('action clicked');
        } else if (actionScheme?.indexOf(object) === actionScheme?.length - 1) {
          return () => console.log('action clicked');
        }
      }
    } else {
      return () => console.log('action clicked');
    }
  };

  /* this sets the icons for action buttons */
  const getButtonIcon = (actionScheme, data, rowItem) => {
    function fetchIcon(variant) {
      switch (variant) {
        case 'print':
          return (
            <Icon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}>
              <CgPrinter size="md" />
            </Icon>
          );
        case 'read':
          return (
            <Icon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}>
              <CgEnter size="md" />
            </Icon>
          );
        case 'write':
          return (
            <EditIcon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}
            />
          );
        case 'kill':
          return (
            <DeleteIcon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}
            />
          );
        case 'activate':
          return (
            <Icon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}>
              <RiUserUnfollowLine size="md" />
            </Icon>
          );
        case 'deactivate':
          return (
            <Icon
              w="24px"
              h="24px"
              cursor="pointer"
              color={getButtonVariant(actionScheme, data).color}
              _hover={{ color: getButtonVariant(actionScheme, data)?.hoverColor }}
              onClick={() => getButtonAction(actionScheme, data, rowItem)}>
              <RiUserFollowLine size="md" />
            </Icon>
          );
        default:
          return <Text></Text>;
      }
    }

    if (actionScheme?.length > 0) {
      for (let object of actionScheme) {
        if (object?.group === data) {
          return fetchIcon(object?.variant);
        } else if (object?.group === 'boolean') {
          return data ? fetchIcon(object?.trueVariant) : fetchIcon(object?.falseVariant);
        } else if (actionScheme?.indexOf(object) === actionScheme?.length - 1) {
          return fetchIcon();
        }
      }
    } else {
      return fetchIcon();
    }
  };

  /* this sets the icons for icon only columns */
  const getColumnIcon = (iconScheme, data) => {
    if (iconScheme?.length > 0) {
      for (let object of iconScheme) {
        if (object?.group === data) {
          return <Icon as={object?.icon} color={object?.color} w={object?.size} h={object?.size} />;
        } else if (object?.group === 'boolean') {
          return (
            <Icon
              as={data ? object?.trueIcon : object?.falseIcon}
              color={data ? object?.trueColor : object?.falseColor}
              w={object?.size}
              h={object?.size}
            />
          );
        } else if (iconScheme?.indexOf(object) === iconScheme?.length - 1) {
          return <Text></Text>;
        }
      }
    } else {
      return <Text></Text>;
    }
  };

  /* checklist to select rows */
  const selectAll = () => {
    if (selectedItems?.length == data?.length) {
      setSelectedItems([]);
    } else {
      let dataArray = [];
      for (let item of data) {
        if (item.id) {
          dataArray.push(item.id);
        }
      }
      setSelectedItems(dataArray);
    }
  };

  /* this renders the cell data */
  const renderCellData = (rowItem, columnItem, colIndex) => {
    return columnItem?.isCheckBox ? (
      <Td key={colIndex}>
        <Checkbox
          colorScheme="primary"
          size="lg"
          isChecked={selectedItems?.includes(rowItem[columnItem?.accessor])}
          onChange={() => {
            if (selectedItems?.includes(rowItem[columnItem?.accessor])) {
              const newSelectedItems = selectedItems?.filter(
                (item) => item !== rowItem[columnItem?.accessor],
              );
              setSelectedItems(newSelectedItems);
            } else {
              setSelectedItems([...selectedItems, rowItem[columnItem?.accessor]]);
            }
          }}
        />
      </Td>
    ) : columnItem?.isFile ? (
      rowItem[columnItem?.accessor]?.split('.')?.[
        rowItem[columnItem?.accessor]?.split('.')?.length - 1
      ] === 'pdf' ? (
        <Td key={colIndex}>
          <a href={rowItem[columnItem?.accessor]} target="_blank" rel="noreferrer" draggable>
            <Icon as={AiOutlineFilePdf} w={8} h={8} color="invalid" pb={1} />
          </a>
        </Td>
      ) : (
        <Td key={colIndex}>
          <a href={rowItem[columnItem?.accessor]} target="_blank" rel="noreferrer" draggable>
            <Image src={rowItem[columnItem?.accessor]} fallbackSrc={Placeholder} boxSize="50px" />
          </a>
        </Td>
      )
    ) : columnItem?.isDate ? (
      <Td key={colIndex} fontSize="sm">
        {formatDate(rowItem[columnItem?.accessor])}
      </Td>
    ) : columnItem?.isBadge ? (
      <Td key={colIndex}>
        <Tooltip
          label={columnItem?.hoverInfo ? getBadgeTooltipText(rowItem, columnItem?.hoverInfo) : ''}
          aria-label="A tooltip">
          <Badge
            colorScheme={getBadgeColor(columnItem?.colorScheme, rowItem[columnItem?.accessor])}
            pl={2}
            pr={2}>
            {rowItem[columnItem?.accessor]}
          </Badge>
        </Tooltip>
      </Td>
    ) : columnItem?.isButton ? (
      <Td key={colIndex} textAlign="center">
        <Tooltip
          label={getButtonText(columnItem?.actionScheme, rowItem[columnItem?.accessor])}
          aria-label="A tooltip">
          {getButtonIcon(columnItem?.actionScheme, rowItem[columnItem?.accessor], rowItem)}
        </Tooltip>
      </Td>
    ) : columnItem?.isIcon ? (
      <Td key={colIndex} textAlign="center">
        {getColumnIcon(columnItem?.iconScheme, rowItem[columnItem?.accessor], rowItem)}
      </Td>
    ) : (
      <Tooltip
        label={columnItem?.hoverInfo ? getBadgeTooltipText(rowItem, columnItem?.hoverInfo) : ''}
        aria-label="A tooltip">
        <Td key={colIndex} fontSize="sm">
          {columnItem?.currency ? columnItem?.currency : null}
          {columnItem?.limit && rowItem[columnItem?.accessor]?.length > columnItem?.limit
            ? `${rowItem[columnItem?.accessor].substring(0, columnItem?.limit)}${
                rowItem[columnItem?.accessor]?.length > columnItem?.limit ? '...' : ''
              }` || 'N/A'
            : rowItem[columnItem?.accessor] === 0
            ? 0
            : rowItem[columnItem?.accessor] || 'N/A'}
        </Td>
      </Tooltip>
    );
  };

  /* renders table UI */
  return (
    <Box>
      <Box bg="white" px={4} pt={6}>
        <HStack justifyContent="space-between" flexWrap="wrap" spacing={0}>
          <Text fontSize="xl" fontWeight="bold" color="black" textAlign="left" mr={5}>
            {caption ? caption : 'Data Table'}
          </Text>
          {topPanel ? (
            <VStack pt={2}>
              <HStack as="form" onSubmit={handleSearch} justifyContent="right" spacing={1}>
                {selectedItems?.length > 0 && rowSelectScheme?.length > 0 ? (
                  <HStack spacing={1}>
                    {rowSelectScheme.includes('EXPORT') ? (
                      <Button
                        rightIcon={<Icon as={TiExport} w={5} h={5} />}
                        onClick={() => onActionClick(selectedItems, setSelectedItems, 'EXPORT')}>
                        Export as CSV
                      </Button>
                    ) : null}
                    {selectedItems?.length == 1 && rowSelectScheme.includes('EDIT') ? (
                      <Button
                        rightIcon={<Icon as={MdModeEdit} w={5} h={5} />}
                        onClick={() => onActionClick(selectedItems, setSelectedItems, 'EDIT')}>
                        Edit
                      </Button>
                    ) : null}
                    {rowSelectScheme.includes('DELETE') ? (
                      <Button
                        bg="red.500"
                        _hover={{ bg: 'red.400' }}
                        rightIcon={<Icon as={MdDelete} w={5} h={5} />}
                        onClick={() => onActionClick(selectedItems, setSelectedItems, 'DELETE')}>
                        Delete
                      </Button>
                    ) : null}
                    {rowSelectScheme.includes('REVOKE') ? (
                      <Button
                        bg="red.500"
                        _hover={{ bg: 'red.400' }}
                        _focus={{ boxShadow: 'none' }}
                        rightIcon={<Icon as={MdCancel} w={5} h={5} />}
                        onClick={() => onActionClick(selectedItems, setSelectedItems, 'REVOKE')}>
                        Revoke
                      </Button>
                    ) : null}
                    {rowSelectScheme.includes('RELEASE') ? (
                      <Button
                        bg="red.500"
                        _hover={{ bg: 'red.400' }}
                        _focus={{ boxShadow: 'none' }}
                        rightIcon={<Icon as={MdCancel} w={5} h={5} />}
                        onClick={() => onActionClick(selectedItems, setSelectedItems, 'RELEASE')}>
                        Release
                      </Button>
                    ) : null}
                  </HStack>
                ) : null}
                {keySearch ? (
                  <InputGroup>
                    <InputRightElement>
                      <Icon as={MdSearch} w={6} h={6} />
                    </InputRightElement>
                    <Input
                      variant="outline"
                      borderRadius="2px"
                      focusBorderColor="primary.300"
                      value={searchKey || ''}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                      }}
                      placeholder="Search"
                    />
                  </InputGroup>
                ) : null}
                {dateRange ? (
                  <DateRangePicker
                    spacing={1}
                    data={(dateRange) => handleDateRange(dateRange)}
                    validation={(isValid) => setDateRangeValidation(isValid)}
                    error={(str) => setDateErrorMsg(str)}
                    clearSelection={clearDateSelect}
                    cleared={() => setClearDateSelect(false)}
                  />
                ) : null}
                {selectOptions?.map((selectField, index) => (
                  <Tooltip
                    key={index}
                    label={selectField.name || 'Select An Option'}
                    aria-label="A tooltip">
                    <Select
                      maxW="fit-content"
                      variant="outline"
                      borderRadius="2px"
                      value={selected?.[selectField.name] || 'Select An Option'}
                      focusBorderColor="primary.300"
                      placeholder={selectField.name || 'An Option'}
                      onChange={(e) => {
                        if (e.target.value) {
                          setSelected({ ...selected, [selectField.name]: e.target.value });
                        } else {
                          setSelected('');
                        }
                      }}>
                      {selectField?.choices?.map((choice) => (
                        <option key={choice?.value} value={choice?.value}>
                          {choice?.name}
                        </option>
                      ))}
                    </Select>
                  </Tooltip>
                ))}
                <Tooltip label="Search" aria-label="A tooltip">
                  <IconButton
                    type="submit"
                    variant="tableAction"
                    icon={<Search2Icon size="24px" />}
                  />
                </Tooltip>
                <Tooltip label="Reset table" aria-label="A tooltip">
                  <IconButton
                    variant="tableAction"
                    icon={<MdRefresh size="24px" />}
                    onClick={clearSearchFields}
                  />
                </Tooltip>
              </HStack>
              <Text color="invalid" fontSize="15px" pl={keySearch ? '175px' : null}>
                {dateErrorMsg}
              </Text>
            </VStack>
          ) : null}
        </HStack>
      </Box>
      <Box px={2} pb={4} bg="white">
        <Divider />
      </Box>
      <Stack bg="white" px={4} pb={4} maxH="60vh" overflow="auto">
        {data ? (
          <Table size="sm">
            {columns ? (
              <>
                <Thead fontSize="xl" fontWeight="bold" color="black">
                  <Tr position="sticky" top="0px" background="#F6F7FC" zIndex={1}>
                    {columns.map((columnItem, index) =>
                      columnItem.isCheckBox ? (
                        <Tooltip label="Select all" aria-label="A tooltip" key={index}>
                          <Th textAlign="left" fontWeight="bold" fontSize="16px" color="Black">
                            <Icon
                              as={
                                selectedItems?.length == data?.length &&
                                (data?.length !== 0 || selectedItems?.length !== 0)
                                  ? AiFillCheckSquare
                                  : AiOutlineCheckSquare
                              }
                              w={7}
                              h={7}
                              onClick={selectAll}
                              cursor="pointer"
                            />
                          </Th>
                        </Tooltip>
                      ) : (
                        <Th
                          key={index}
                          textAlign={columnItem.isButton || columnItem?.isIcon ? 'center' : 'left'}
                          fontWeight="bold"
                          fontSize="16px"
                          color="Black">
                          {columnItem.header}
                        </Th>
                      ),
                    )}
                  </Tr>
                </Thead>
                {data?.length > 0 ? (
                  <Tbody>
                    {data.map((rowItem, idx) => (
                      <Tr key={idx}>
                        {columns.map((columnItem, index) =>
                          renderCellData(rowItem, columnItem, index),
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                ) : (
                  <Tbody position="relative" height="40vh">
                    <Tr
                      fontSize="lg"
                      position="absolute"
                      paddingX={20}
                      paddingY={12}
                      top="50%"
                      left="50%"
                      transform="translate(-50%,-50%)"
                      bg="white">
                      <Text color="textSecondary" fontSize="2xl">
                        <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                      </Text>
                    </Tr>
                  </Tbody>
                )}
              </>
            ) : null}
          </Table>
        ) : (
          <Table>
            <Tbody>
              <Tr>
                <Loader internal />
              </Tr>
            </Tbody>
          </Table>
        )}
      </Stack>
      <Box px={2}>
        <Divider />
      </Box>
      {isPaginated ? (
        <Pagination
          entries={entries}
          pageSizes={pageSizes}
          paginate={paginate}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          currentSearchParams={searchParams}
        />
      ) : null}
    </Box>
  );
};

export default DataTable;
