import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  Stack,
  Box,
  Text,
  Icon,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { MEMBER_DETAILS_URL } from '../../constants/routerUrl';
import { renderDate } from '../../util/formatUtils';
import { RiUser3Line } from 'react-icons/ri';

import InfoSection from '../common/InfoSection';

function DashBoard() {
  const history = useHistory();

  const [renew, setRenew] = useState(false);
  const [millInfo, setMillInfo] = useState();
  const [expired, setExpired] = useState(false);
  const data = useSelector((state) => state.mill.mill);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (data) {
      const basicInformationFields = [
        { header: 'Membership No', value: data?.membershipNo },
        { header: 'Membership Type', value: data?.membershipType },
        { header: 'Category', value: data?.billingData?.summary?.category },
        { header: 'Membership Expiry Date', value: renderDate(data?.membershipExpiryDate) },
        { header: 'Establishment Date', value: renderDate(data?.dateOfEstablishment) },
        { header: 'Represented By', value: data?.millPresentatorInfo?.mpName },
        { header: 'Email Address', value: data?.hoInfo?.millEmail },
        { header: 'Office Phone Number', value: data?.hoInfo?.millPhoneNo },
        { header: 'Name of Chairman', value: data?.chairmanInfo?.chairmanName },
        { header: 'Chairman Phone Number', value: data?.chairmanInfo?.chairmanOfficeTelephone },
        { header: 'Name of Managing Director', value: data?.mdInfo?.mdName },
        { header: 'Managing Director Phone Number', value: data?.mdInfo?.mdOfficeTelephone },
      ];
      setMillInfo(basicInformationFields);
      let date = new Date(data?.membershipExpiryDate);
      let month = date.getMonth();
      date.setMonth(date.getMonth() - 1);
      if (date.getMonth() == month) date.setDate(0);
      if (new Date().setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)) setRenew(true);

      if (new Date() >= new Date(data?.membershipExpiryDate)) setExpired(true);
    } else {
      setMillInfo();
    }
  }, [data]);

  return (
    <Box p={6} bg="secondary">
      <Box mb={6}>
        <Text fontSize="3xl" lineHeight="1" fontWeight="extrabold">
          Hello {user?.fullName},
          <Icon as={RiUser3Line} w={8} h={8} pb={1} color="primary.700" />
        </Text>
        <Text fontSize="md" lineHeight="1" color="textSecondary">
          Welcome to BTMA-clients portal!
        </Text>
      </Box>

      {expired ? (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle>Your membership is expired!</AlertTitle>
          <AlertDescription>Please visit BTMA office to renew your membership.</AlertDescription>
        </Alert>
      ) : renew ? (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle>Your membership is expiring soon!</AlertTitle>
          <AlertDescription>
            Please renew your membership before{' '}
            <span style={{ color: '#E53E3E', fontWeight: 600 }}>
              {renderDate(data?.membershipExpiryDate)}
            </span>
          </AlertDescription>
        </Alert>
      ) : null}

      <Box>
        <Box p="6" borderWidth="1px" borderRadius="md" bg="white" minW="70vw">
          {millInfo ? (
            <InfoSection sectionName="Basic Information" infoFields={millInfo} />
          ) : (
            <Stack flexDir="column" mt="8" justifyContent="center" alignItems="center">
              <Text fontSize="lg" fontWeight="bold" mb="3">
                No associated mills found
              </Text>
              <Button
                color="#fff"
                variant="formNext"
                onClick={() => history.push(MEMBER_DETAILS_URL)}>
                Apply for membership
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default DashBoard;
